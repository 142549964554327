<template>
  <div class="home">
    <div class="banner"><img src="../assets/images/banner1.jpg" alt="" /></div>
    <div class="current-position">
      <el-breadcrumb>
        <el-breadcrumb-item :to="{ path: '/' }"
          ><span class="icon-home"></span>首页</el-breadcrumb-item
        >
        <el-breadcrumb-item>考试</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="test-box">
      <div
        class="test-content"
        :span="12"
        v-for="item in licenceData"
        :key="item.id"
      >
        <div class="test-list">
          <h2>{{ item.licence_name }}</h2>
          <ul>
            <li v-for="value in item.children" :key="value.name">
              <div class="test-item">
                <span
                  class="icon"
                  :class="value.classname"
                  v-if="value.classname"
                ></span>
                <span v-else>
                  <img
                    :src="value.mobile_imgurl"
                    :alt="value.licence_name"
                    width="60px"
                /></span>
                <h3>
                  <router-link
                    :to="{
                      path:
                        '/exam/exercise?parent_id=' +
                        item.licence_id +
                        '&licence_id=' +
                        value.licence_id
                    }"
                    tag="a"
                    target="_blank"
                  >
                    {{ value.licence_name }}
                  </router-link>
                </h3>
                <p>
                  <router-link
                    :to="{
                      path:
                        '/exam/exercise?parent_id=' +
                        item.licence_id +
                        '&licence_id=' +
                        value.licence_id
                    }"
                  >
                    顺序练习
                  </router-link>
                  <router-link
                    :to="{
                      path:
                        '/exam/exercise?parent_id=' +
                        item.licence_id +
                        '&licence_id=' +
                        value.licence_id
                    }"
                  >
                    模拟考试
                  </router-link>
                </p>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getlicence } from '../api/licence'

export default {
  name: 'exam',
  data() {
    return {
      licenceData: []
    }
  },
  components: {},
  created() {
    // this.handleSelect()
    this.getlicenceData()
  },
  methods: {
    handleSelect() {
      this.$alert(
        '网站目前正在升级当中，以免影响正常备考，建议您使用车学堂app进行学习，车学堂app已全面上线“新规题库”并持续更新，可大大提高考试通过率！',
        '温馨提示',
        {
          confirmButtonText: '我知道了',
          type: 'warning',
          center: true,
          showClose: false
        }
      ).then(() => {
        this.$router.go(-1)
      })
    },
    getlicenceData() {
      getlicence().then(res => {
        if (res.code === 0) {
          this.licenceData = res.result
          for (let i = 0; i < this.licenceData.length; i++) {
            if (this.licenceData[i].hasOwnProperty('children') === true) {
              for (let s = 0; s < this.licenceData[i].children.length; s++) {
                if (
                  this.licenceData[i].children[s].classname === null &&
                  this.licenceData[i].children[s].mobile_imgurl === null
                ) {
                  this.licenceData[i].children[s].classname = 'icon-car2'
                }
              }
            }
          }
        }
      })
    }
  }
}
</script>
