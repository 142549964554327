var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"home"},[_vm._m(0),_c('div',{staticClass:"current-position"},[_c('el-breadcrumb',[_c('el-breadcrumb-item',{attrs:{"to":{ path: '/' }}},[_c('span',{staticClass:"icon-home"}),_vm._v("首页")]),_c('el-breadcrumb-item',[_vm._v("考试")])],1)],1),_c('div',{staticClass:"test-box"},_vm._l((_vm.licenceData),function(item){return _c('div',{key:item.id,staticClass:"test-content",attrs:{"span":12}},[_c('div',{staticClass:"test-list"},[_c('h2',[_vm._v(_vm._s(item.licence_name))]),_c('ul',_vm._l((item.children),function(value){return _c('li',{key:value.name},[_c('div',{staticClass:"test-item"},[(value.classname)?_c('span',{staticClass:"icon",class:value.classname}):_c('span',[_c('img',{attrs:{"src":value.mobile_imgurl,"alt":value.licence_name,"width":"60px"}})]),_c('h3',[_c('router-link',{attrs:{"to":{
                    path:
                      '/exam/exercise?parent_id=' +
                      item.licence_id +
                      '&licence_id=' +
                      value.licence_id
                  },"tag":"a","target":"_blank"}},[_vm._v(" "+_vm._s(value.licence_name)+" ")])],1),_c('p',[_c('router-link',{attrs:{"to":{
                    path:
                      '/exam/exercise?parent_id=' +
                      item.licence_id +
                      '&licence_id=' +
                      value.licence_id
                  }}},[_vm._v(" 顺序练习 ")]),_c('router-link',{attrs:{"to":{
                    path:
                      '/exam/exercise?parent_id=' +
                      item.licence_id +
                      '&licence_id=' +
                      value.licence_id
                  }}},[_vm._v(" 模拟考试 ")])],1)])])}),0)])])}),0)])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"banner"},[_c('img',{attrs:{"src":require("../assets/images/banner1.jpg"),"alt":""}})])}]

export { render, staticRenderFns }